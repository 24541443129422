[data-theme="dark"] .fm-narratives-style-normalization p,
[data-theme="dark"] .fm-narratives-style-normalization div,
[data-theme="dark"] .fm-narratives-style-normalization span,
[data-theme="dark"] .fm-narratives-style-normalization strong,
[data-theme="dark"] .fm-narratives-style-normalization sub {
  color: #fff !important;
  background-color: transparent !important;
  font-size: var(--bs-body-font-size) !important;
}

[data-theme="light"] .fm-narratives-style-normalization p,
[data-theme="light"] .fm-narratives-style-normalization div,
[data-theme="light"] .fm-narratives-style-normalization span,
[data-theme="light"] .fm-narratives-style-normalization strong,
[data-theme="light"] .fm-narratives-style-normalization sub  {
  color: #000 !important;
  background-color: transparent !important;
  font-size: var(--bs-body-font-size) !important;
}

/* override bootstrap default tooltip min width */
.tooltip-inner {
  min-width: 300px;
}
