
.mfa-icon:hover {
    color: var(--bs-primary);
}

.resend-icon:hover {
    color: var(--bs-green);
}

.deactivate-icon:hover {
    color: var(--bs-red);
}

.user-admin-table td {
    vertical-align: middle;
}

