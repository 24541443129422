.timeline {
    border-left: 3px solid;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 auto;
    position: relative;
    padding-left: 50px;
    list-style: none;
    text-align: left;
    max-width: 60%;

}

@media (max-width: 1400px) {
    .timeline {
        max-width: 98%;
        padding: 25px;
    }
}

.timeline .event{
    margin-top:5px;
    padding-top:5px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
}

.timeline .year-tick,
.timeline .month-tick{
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
}

@media (max-width: 1400px) {
    .timeline .event {
        padding-top: 30px;
    }
}

.timeline .event:last-of-type,
.timeline .year-tick:last-of-type,
.timeline .month-tick:last-of-type{
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after,
.timeline .year-tick:before,
.timeline .year-tick:after,
.timeline .month-tick:before,
.timeline .month-tick:after{
    position: absolute;
    display: block;
    top: 0;
}

.timeline .event:before,
.timeline .year-tick:before,
.timeline .month-tick:before {
    left: -207px;
    text-align: right;
    font-size: 0.9em;
    min-width: 120px;
    top: 5px;
}

@media (min-width: 1400px) {
    .timeline .timeline-badge {
        font-size: 1em;
        position: absolute;
        z-index: 100;
        left: -35%;
        width: 30%;
        display: flex;
        justify-content: flex-end;
        padding-top:5px;
    }
}

@media (max-width: 1400px) {
    .timeline .timeline-badge {
        font-size: 1em;
        position: absolute;
        z-index: 100;
        width: 30%;
        left: 25px;
        text-align: left;
        padding-top:5px;
    }
}

@media (max-width: 1400px) {
    .timeline .event:before,
    .timeline .year-tick:before{
        left: 0px;
        text-align: left;
    }
}

.timeline .event:after {
    -webkit-box-shadow: 0 0 0 3px var(--call-accent);
    box-shadow: 0 0 0 3px var(--call-accent);
    left: -55px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 15px;
}

.timeline .year-tick:after {
    -webkit-box-shadow: 0 0 0 3px;
    box-shadow: 0 0 0 3px;
    left: -55px;
    width: 9px;
    content: "";
    top: 10px;
}

.timeline .month-tick:after {
    -webkit-box-shadow: 0 0 0 3px var(--bs-secondary);
    box-shadow: 0 0 0 3px var(--bs-secondary);
    left: -55px;
    width: 9px;
    content: "";
    top: 10px;
}

@media (max-width: 1400px) {
    .timeline .event:after,
    .timeline .year-tick:after,
    .timeline .month-tick:after {
        left: -31.8px;
    }
}
