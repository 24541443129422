.accordion-button.not-active {
    background-color: var(--bs-gray-100) !important;
}

[data-theme="dark"] .accordion-button.not-active {
    background-color: var(--bs-dark) !important;
}

.filter-modal {
    overflow-x: hidden;
    padding: 25px;
}

/* Same as modal filter selected badges */
.fm-btn-danger-hover:hover {
    background-color: #FFBDAD;
    color: #DE350B;
}

.react-select__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.fm-filter-badge {
    background-color: rgb(230, 230, 230) !important;
}

[data-theme="dark"] .react-select__multi-value {
    background-color: rgb(158, 158, 158) !important;
}

[data-theme="dark"] .fm-filter-badge {
    background-color: rgb(158, 158, 158) !important;
}

[data-theme="dark"] .react-select-container {
    .react-select__control {
        background-color: rgba(0, 0, 0, 0);
        border-color: var(--bs-border-color);
        transition: none;

        &:hover {
            border-color: var(--bs-primary);
        }
    }

    .react-select__menu {
        background-color: black;
        border: 1px solid var(--bs-primary)
    }

    .react-select__control--is-disabled {
        .react-select__value-container,
        .react-select__indicator {
            color: grey;
        }
    }

    .react-select__input {
        color: white !important;
    }

    .react-select__option {

        &:hover {
            cursor: pointer !important;
            background-color: var(--bs-primary);
        }
    }

    .react-select__option--is-focused {
        background-color: rgba(0, 0, 0, 0);
    }

    .react-select__option--is-disabled {
        background-color: var(--bs-border-color);
        color: var(--bs-gray-500);

        &:hover {
            background-color: var(--bs-gray-800);
        }
    }

    .react-select__indicator-separator {
        background-color: var(--bs-border-color)
    }

    .react-select__placeholder,
    .react-select__single-value {
        color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
    }
    .react-select__multi-value__label {
        color: white;
    }
}

[data-theme="light"] .react-select-container {

    .react-select__option {

        &:hover {
            cursor: pointer !important;
            background-color: var(--bs-info);
        }
    }

    .react-select__option--is-disabled {
        background-color: var(--bs-border-color);
        color: var(--bs-gray-600);

        &:hover {
            background-color: var(--bs-gray-400);
        }
    }

}
