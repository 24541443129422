.radar-event {
    height: calc(100vh - 175px);
    overflow-y: auto;
}

.radar-right {
	height: calc(100vh - 175px);
    overflow: hidden;
}

.radar-person {
	height: 50%;
	overflow-y: auto;
}

.radar-viz {
	height: 50%;
	overflow: auto;
}

