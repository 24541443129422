:root {
    --list-item-cell-color: var(--bs-gray-100);
}

[data-theme="dark"] {
    --list-item-cell-color: var(--bs-gray-800);
}

.card-cell {
    background-color: var(--list-item-cell-color);
}

.disabled-link .disabled {
    color: #6c757d !important;
}
